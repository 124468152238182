<template>
  <div class="bitrix">
    <div class="bitrix-info">
      <div class="bitrix-info__content">
        <page-title class="bitrix-info__title">
          {{ $t('title') }}
        </page-title>
        <div v-if="list.length" class="bitrix-mode">
          <plain-button
            icon="grid"
            class="bitrix-mode__btn"
            watch-active
            :active="mode === 'grid'"
            @click="setMode('grid')"
          />
          <plain-button
            icon="list"
            class="bitrix-mode__btn"
            watch-active
            :active="mode === 'table'"
            @click="setMode('table')"
          />
        </div>
      </div>
      <div v-if="pricelistsAvailable" :class="{ visible: list.length }" class="bitrix-info__create">
        <base-button :to="{ name: 'bitrixOrder' }">
          {{ $t('newServer') }}
        </base-button>
      </div>
    </div>
    <transition name="fade" mode="out-in" appear>
      <div v-if="isLoading" class="bitrix-info__loader">
        <base-loader class="bitrix-info__loader-item" />
      </div>
      <div v-else class="bitrix-content">
        <base-alert
          v-if="isServerError"
          :title="$t('errors.serverError.title')"
          icon="warn"
          class="bitrix-info__alert"
        >
          {{ $t('errors.serverError.text') }}
        </base-alert>
        <base-empty
          v-else-if="!list.length && !isServerError"
          :title="$t('empty.title')"
          :text="$t('empty.text')"
          :textlink="$t('empty.textlink')"
          :textlink2="$t('empty.textlink2')"
          :text2="$t('empty.text2')"
          :link="{ title: $t('createServer'), to: { name: 'bitrixOrder' } }"
          class="bitrix-info__empty"
        />
        <div v-else class="bitrix-content__list">
          <transition name="fade" mode="out-in">
            <component :is="modeComponents[mode]" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TariffsGrid from './components/TariffsGrid';
import TariffsTable from './components/TariffsTable';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../../mixins/index';
import priceListMixin from '@/mixins/priceListMixin';
export default {
  name: 'BitrixMain',
  components: {
    TariffsGrid,
    TariffsTable,
    BaseEmpty,
    BaseAlert,
  },
  mixins: [storeMixin, priceListMixin],
  data() {
    return {
      modeComponents: {
        grid: TariffsGrid,
        table: TariffsTable,
      },
      isServerError: false,
      pricelistsAvailable: false,
      path: 'moduleBitrix/moduleBitrixOrder',
    };
  },
  computed: {
    mode() {
      return this.$store.state.moduleBitrix.mode;
    },
  },
  mounted() {
    if (!this.list.length) {
      this.fetchList().catch(e => {
        if (e.status && e.status === 520) this.isServerError = true;
      });
    }
    this.fetchPricelist(this.path);
  },
  methods: {
    setMode(val) {
      this.$store.commit('moduleBitrix/SET_MODE', val);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Лицензии Bitrix",
      "newServer": "Новая лицензия",
      "createServer": "Купить лицензию",
      "empty": {
        "title": "На данный момент у вас нет ни одной лицензии Bitrix",
        "text": "Лицензии Bitrix - соврменные решения для создания сайта и CRM- системы. При покупке или продлении лицензии \"1С-Битрикс\", вы получаете в подарок виртуальный сервер из линейки \"Сервер без забот™\"! Подробнее: ",
        "textlink": "https://www.rusonyx.ru/1c-bitrix/license/",
        "text2": " А при выборе лицензии \"1С-Битрикс 24\", дарим хостинг и SSL сертификат на год! Подробнее: ",
        "textlink2": "https://www.rusonyx.ru/bitrix24/license/"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.bitrix {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 165px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
